import React from "react"
import {Link} from 'gatsby';
import Layout from "../../components/actiontimer/layout"
// import logo from "../../images/actiontimer/applogo.svg"
import seologo from "../../images/actiontimer/appicon256.png"
import Seo from "../../components/SEO"

function PrivacyPage(props) {

    const seoImage = {
		src: seologo,
		height: 256,
		width: 256,
		alt: "ActionTimer for Mac app logo",
    };

    const appStoreCampaignUrlActionTimer = "https://apps.apple.com/app/apple-store/id1518277131?pt=121290356&ct=malaudhome&mt=8"

    return (
    <Layout>
        <Seo
            siteTitle={'ActionTimer Privacy Policy'}
            seoTitle='ActionTimer Privacy Policy'
            path={props.location.pathname}
            metaImage={seoImage}
            icon={seologo}
            description='ActionTimer for Mac - Privacy Policy'
            keywords={["ActionTimer", "Privacy Policy"]}
            datePublished="09-02-2020"
            dateModified="11-01-2022"
        />
        
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1 style={{color: 'chocolate'}}>ActionTimer for Mac</h1>
                    <h2>Privacy Policy</h2>
                </div>
                
                <h3>Personal Data</h3>
                <p>
                    <a href={appStoreCampaignUrlActionTimer} target={"_blank"} rel="noreferrer" style={{textDecoration: 'None', color: 'chocolate'}} title={"ActionTimer for Mac"}>ActionTimer for Mac</a> does not collect or transfer any personal data on the user's device.
                </p>

                <h3>Data Sharing</h3>
                <p>
                    ActionTimer does not collect, share or sell any personal or anonymous data with any third party or any advertising company.
                </p>

                <h3>Diagnostic Data</h3>
                <p>
                    When you have enabled sharing anonymous diagnostic information on your devices, Apple may share any app related diagnostic information, crash logs, etc. with us. This information does not contain any of your personal information or any of the device information, and is only used to identify any problems with the app to provide you a great user experience.
                </p>

                <h3>Support Data</h3>
                <p>
                    When you contact support, you provide your email, name and other information that may be relevant to your support query. This information will be stored on our servers and for the sole purpose of answering your support request and for legal & administrative purposes.
                </p>

                <h3>Consent</h3>
                <p>
                    By using our website, you hereby consent to our website <Link to="/privacy" style={{textDecoration: 'None', color: 'chocolate'}}>Privacy Policy</Link>.
                    If you have additional questions or require more information, do not hesitate to <Link to="/contact" style={{textDecoration: 'None', color: 'chocolate'}}>contact us</Link>.
                </p>

            </div>
        </div>
    </Layout>
    )
}

export default PrivacyPage
